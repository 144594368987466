<template>
    <nav role="navigation" aria-label="breadcrumbs" v-bind="$attrs" class="crumb-banner" :class="{ active: inMotion }">
        <div class="crumb-banner__content" ref="crumbPosition">
            <slot />
        </div>
    </nav>
</template>
<script>
export default {
    data: () => {
        return {
            inMotion: false,
        };
    },

    methods: {
        setupIntersectionObserver(element) {
            const options = {
                root: null,
                rootMargin: "0px",
                threshold: 1,
            };
            const observer = new IntersectionObserver(([entry] = entries) => {
                this.inMotion = entry.intersectionRatio < 1;
            }, options);

            observer.observe(element);
        },
    },

    mounted() {
        this.setupIntersectionObserver(this.$refs.crumbPosition);
    },
};
</script>
