<template>
    <Page behind-nav class="insight-landing">
        <section class="insight-landing__content">
            <CrumbBanner class="insight-landing__filter-banner category-filters" :class="{ 'filters-active': categoryDropdownActive }">
                <div class="container">
                    <h1 class="insight-landing__recent-title text-heading text-heading--xs">{{ $t("insight.page_title") }}</h1>
                    <div class="insight-landing__filters">
                        <ProButton
                            @click="setCategoryDropdownActive(!categoryDropdownActive)"
                            role="button"
                            aria-haspopup="true"
                            aria-controls="category-filters__dropdown"
                            :aria-expanded="categoryDropdownActive"
                            class="category-filters__toggle"
                            >{{ $t("insight.filters.title") }} <ProIcon icon="arrow-down" v-if="!categoryDropdownActive" /><ProIcon
                                v-if="categoryDropdownActive"
                                icon="modal-close"
                        /></ProButton>
                    </div>
                </div>
                <div class="category-filters__dropdown" id="category-filters__dropdown" role="menu">
                    <ul class="container" :aria-hidden="!categoryDropdownActive" role="presentation">
                        <li
                            class="category-filters__option"
                            :class="{ active: currentCategory == null }"
                            role="menuitem"
                            :aria-selected="currentCategory == null"
                        >
                            <ProButton :tabindex="categoryDropdownActive ? 0 : -1" :to="{ name: 'insight' }" :class="{ active: currentCategory == null }">{{
                                $t("insight.filters.see_all")
                            }}</ProButton>
                        </li>
                        <li
                            v-for="category in categories"
                            :key="category.id"
                            class="category-filters__option"
                            :class="{ active: category.id == currentCategory?.id }"
                            role="menuitem"
                            :aria-selected="category.id == currentCategory?.id"
                        >
                            <ProButton :tabindex="categoryDropdownActive ? 0 : -1" :to="{ name: 'insight', query: { category: category.slug } }">{{
                                category.title
                            }}</ProButton>
                        </li>
                    </ul>
                </div>
            </CrumbBanner>
            <div class="insight-landing__active-filters container">
                <ProButton v-if="currentCategory" class="insight-landing__active-filter" :aria-label="$t('insight.filters.remove')" :to="{ name: 'insight' }"
                    >{{ currentCategory.title }}<ProIcon icon="modal-close"
                /></ProButton>
            </div>

            <div class="container">
                <div v-if="currentCategory == null && featuredArticle" class="insight-landing__grid featured">
                    <div class="insight-landing__tile-container">
                        <InsightLinkTile :article="featuredArticle" layout="featured" />
                    </div>
                </div>
                <div class="insight-landing__grid" ref="articles" :class="{ filtered: currentCategory != null }">
                    <div
                        v-for="article in currentCategory != null ? articles : notFeaturedArticles"
                        :key="`${article.slug}-${article.language}`"
                        class="insight-landing__tile-container"
                    >
                        <InsightLinkTile :article="article" />
                    </div>
                </div>

                <div v-if="totalArticleCount && articles.length < totalArticleCount" class="insight-landing__archive-actions">
                    <ProButton id="load-more" pill variant="alt" :action="nextPage">{{ $t("insight.see_more") }}</ProButton>
                </div>
            </div>
        </section>
    </Page>
</template>

<script>
import { mapGetters } from "vuex";
import { metaTitles, metaDescriptions, metaImages } from "~/utils/meta";
import InsightLinkTile from "@/components/insight/InsightLinkTile";
import CrumbBanner from "@/components/CrumbBanner";

const ARTICLE_PAGE_LIMIT = 12;

export default {
    components: {
        InsightLinkTile,
        CrumbBanner,
    },

    watchQuery: ["category"],

    data: () => ({
        categoryDropdownActive: false,
    }),

    computed: {
        ...mapGetters({
            categories: "insight/getCategories",
            articles: "insight/getArticles",
            currentCategory: "insight/getCurrentCategory",
            nextCursor: "insight/getNextCursor",
            totalArticleCount: "insight/getTotalArticleCount",
        }),
        featuredArticle() {
            return this.articles.find((a) => a.featured == true);
        },
        notFeaturedArticles() {
            return this.articles.filter((a) => a.featured == false);
        },
    },

    async asyncData({ app, store, route, redirect }) {
        await store.dispatch("insight/fetchCategories");
        const routeCategory = route.query?.category ?? null;
        const categoryChanged = routeCategory != store.getters["insight/getCurrentCategory"]?.slug;

        store.commit("insight/setCurrentCategory", routeCategory);

        // if route specified category doesn't exists for locale, go to base route.
        if (store.getters["insight/getCurrentCategory"]?.slug != routeCategory) {
            redirect(302, app.localePath({ name: "insight" }));
        } else {
            const localeChanged = store.getters["insight/getArticles"][0]?.language != app.i18n.locales.find((l) => l.code == app.i18n.locale).iso;
            if (store.getters["insight/getArticles"].length <= ARTICLE_PAGE_LIMIT || categoryChanged || localeChanged) {
                await store.dispatch("insight/fetchArticlesInitial");
            }
        }
    },

    methods: {
        setCategoryDropdownActive(active) {
            this.categoryDropdownActive = active;
        },

        async nextPage() {
            const lastItem = this.$refs.articles.querySelector(".insight-landing__tile-container:last-child");
            await this.$store.dispatch("insight/fetchArticles", {
                limit: ARTICLE_PAGE_LIMIT,
                cursorDate: this.nextCursor.date,
                cursorSlug: this.nextCursor.slug,
            });

            //focus to next item (not bottom of page)
            lastItem.nextSibling?.querySelector("a.insight-link-tile").focus();
        },
    },

    head() {
        const i18nSeo = this.$nuxtI18nSeo();
        return {
            title: this.$t("meta.insight.title"),
            meta: [
                ...metaTitles(this.$t("meta.insight.title")),
                ...metaDescriptions(this.$t("meta.insight.description")),
                ...metaImages(this.$asset(this.$t("meta.insight.image"))),
                ...i18nSeo.meta,
            ],
            link: [...i18nSeo.link],
        };
    },
};
</script>
